.Spinnerborder {
  margin-top: 10%;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to {
      transform: rotate(360deg);
  }
}

.center {
  text-align: center;
  margin: 5rem auto;
}